import React, { useEffect, useState } from 'react';
import "./study.css";
import europe from '../../images/europeherobg.jpg'
import { TiTick } from 'react-icons/ti'
import { admissionProcessEurope, famousUniversitiesEurope, reasonsToChooseEurope, requiredDocumentsEurope, studentLifeInEurope } from './studyvisaHelper';

const StudyEurope = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const buttonsText = ["About Europe", "Student Life in Europe", "Admission in Europe", "Universities in Europe"];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section className='mt-16 pt-5 herostudineurope'>
                <div className='container max-w-7xl h-72 flex justify-center lg:justify-start text-center lg:text-left items-center   px-5 py-12 text-white   mx-auto'>
                    <div className='  lg:text-left'>
                        <h2 className='uppercase  font-bold  strokeme text-4xl lg:text-3xl'>Study In Europe</h2>
                        <h2 className='capitalize mt-2 sm:text-lg md:text-xl '>home- Study In Europe</h2>
                    </div>
                </div>
            </section>
            <section>
                <div className='container max-w-7xl px-5 mx-auto py-8 md:py-14 '>
                    <div className='overflow-auto xl:flex justify-center'>
                        <div className='min-w-max overflow-auto justify-between'>
                            {buttonsText.map((item, index) => <button onClick={() => handleTabClick(index)} className={`capitalize   font-semibold min-w-max  py-[10px] px-5 border transition-all duration-300 ${activeTab === index && "bg-white text-[#051c39]"} hover:bg-white  hover:text-[#051c39]`}>{item}</button>)}
                        </div>
                    </div>
                    <div className='contentofstudy py-10'>
                        {activeTab === 0 && <div>
                            <div>
                                <img loading='lazy' className='w-full' src={europe} alt="banner" />
                                <p className='studyinparagraph'>{reasonsToChooseEurope.introduction}</p>
                                <div className='md:text-xl  xl:text-[22px] my-9'>
                                    <h3 className='subheading'>A. Academic Excellence and Diversity</h3>
                                    <ol className='mt-8 text-justify'>
                                        {reasonsToChooseEurope.academicExcellence.map((item, i) => <li key={i + 10} className='md:text-xl xl:text-[22px] my-4 flex'><span className='inline-block max-w-[40px] text-2xl pr-3'><TiTick /></span>{item} </li>
                                        )}
                                    </ol>
                                </div>
                                <div className='md:text-xl  xl:text-[22px] my-9'>
                                    <h3 className='subheading'>B. Cultural Enrichment and Diversity</h3>
                                    <ol className='mt-8 text-justify'>
                                        {reasonsToChooseEurope.culturalEnrichment.map((item, i) => <li key={i + 10} className='md:text-xl xl:text-[22px] my-4 flex'><span className='inline-block max-w-[40px] text-2xl pr-3'><TiTick /></span>{item} </li>
                                        )}
                                    </ol>
                                </div>
                                <div className='md:text-xl  xl:text-[22px] my-9'>
                                    <h3 className='subheading'>C. International Networking Opportunities</h3>
                                    <ol className='mt-8 text-justify'>
                                        {reasonsToChooseEurope.internationalNetwork.map((item, i) => <li key={i + 10} className='md:text-xl xl:text-[22px] my-4 flex'><span className='inline-block max-w-[40px] text-2xl pr-3'><TiTick /></span>{item} </li>
                                        )}
                                    </ol>
                                </div>
                                <div className='md:text-xl  xl:text-[22px] my-9'>
                                    <h3 className='subheading'>D. Cutting-Edge Research and Innovation</h3>
                                    <ol className='mt-8 text-justify'>
                                        {reasonsToChooseEurope.cuttingEdgeResearch.map((item, i) => <li key={i + 10} className='md:text-xl xl:text-[22px] my-4 flex'><span className='inline-block max-w-[40px] text-2xl pr-3'><TiTick /></span>{item} </li>
                                        )}
                                    </ol>
                                </div>
                                <div className='md:text-xl  xl:text-[22px] my-9'>
                                    <h3 className='subheading'>E. Language and Multilingualisms</h3>
                                    <ol className='mt-8 text-justify'>
                                        {reasonsToChooseEurope.languageOpportunities.map((item, i) => <li key={i + 10} className='md:text-xl xl:text-[22px] my-4 flex'><span className='inline-block max-w-[40px] text-2xl pr-3'><TiTick /></span>{item} </li>
                                        )}
                                    </ol>
                                </div>
                            </div>
                        </div>}

                        {activeTab === 1 && <div>
                            <div className='md:text-xl  xl:text-[22px] my-9'>
                                <h3 className='subheading'>Student Life in Europe for International Students:</h3>
                                <ol className='mt-8 text-justify'>
                                    {studentLifeInEurope.map((item, i) => <li key={i + 10} className='md:text-xl xl:text-[22px] my-4 flex'><span className='inline-block max-w-[40px] text-2xl pr-3'><TiTick /></span>{item}</li>)}
                                </ol>
                            </div>





                        </div>}

                        {activeTab === 2 && <div>
                            <h3 className='subheading'>Admission Process for International Students in Europe:</h3>
                            <ol className='mt-8 text-justify'>
                                {admissionProcessEurope.map((item, i) => <li key={i + 10} className='md:text-xl xl:text-[22px] my-4 flex'><span className='inline-block max-w-[40px] text-2xl pr-3'><TiTick /></span>{item}</li>)}
                            </ol>
                            <div className='md:text-xl  xl:text-[22px] my-9'>
                                <h3 className='subheading'>Required Documents for Admission in Europe:</h3>
                                <ol className='mt-8 text-justify'>
                                    {requiredDocumentsEurope.map((item, i) => <li key={i + 12} className='md:text-xl xl:text-[22px] my-4 flex'><span className='inline-block max-w-[40px] text-2xl pr-3'><TiTick /></span>{item}</li>)}
                                </ol>
                            </div>

                        </div>}
                        {activeTab === 3 && <ul className='flex flex-wrap justify-between'>
                            {famousUniversitiesEurope.map((item, i) => <li key={i + 13} className='md:text-xl w-96 lg:w-[500px] xl:text-[22px] my-4 flex items-center justify-start  '><TiTick className='mr-3' />{item}
                            </li>)}
                        </ul>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default StudyEurope