export const studentlifeInCanada = [
    "Canada stands as a coveted haven for international students, drawing them in with its high-caliber education and vibrant student life. Immersing yourself in Canadian academia not only promises academic growth but also opens doors to a realm of novel cultures and experiences. As you embark on this journey, you'll be embracing a whole new way of life, enriched by the camaraderie of friends from diverse corners of the globe through the myriad activities organized by colleges and universities.",

    "Homestays (University-Provided Accommodation): A heartwarming facet of Canadian life is the warm embrace extended by many Canadian families to international students. Homestays furnish an environment of stability and security, a boon especially for younger scholars. Numerous educational institutions facilitate homestay arrangements, matching students with like-minded families sharing similar interests.",
    "Residence / Dormitory (University-Provided Accommodation): Many universities provide conveniently situated accommodations right on or near their campuses. These dwellings offer a range of room sizes and qualities. Many dormitories feature shared facilities, including kitchens, restrooms, showers, and laundry amenities.",
    "Off-Campus Housing: Renting an off-campus residence is a viable option for international students entering Canada. Many opt to share accommodations, mitigating costs and often finding living spaces that align with their preferences. Numerous schools extend an off-campus housing service, offering budget-friendly listings in close proximity to the campus. Here, those in search of shared lodging can also seek out compatible roommates. Upon reaching the campus, students frequently encounter postings advertising nearby housing, though it's advisable to make arrangements before arriving in Canada for a smoother transition."]

export const whyCanada = ["Education holds paramount significance to Canadians, leading to the establishment of a top-tier educational system upheld by rigorous standards. Canada, offering an array of benefits to international scholars, garners global recognition for its sterling reputation. It embraces a secure, multicultural milieu, fostering an inviting haven for academic pursuits. Eminent for their caliber, Canadian educational institutions bestow worldwide-respected certifications, rendering a Canadian education an exceptional investment due to its relatively modest tuition fees.",

    "Canadian youths consistently achieve exceptional scores on international assessments in reading, science, and math, underscoring the remarkable caliber of Canadian educational institutions. A Canadian academic accolade, whether a degree, diploma, or certificate, holds substantial esteem in corporate, governmental, and academic circles worldwide.",

    "Engaging with Canada's natural splendor is a privilege for students. The nation's breathtaking landscapes encompass an array of geographical wonders: from British Columbia's lush coastlines to the grandeur of Alberta's Rocky Mountains, the vast prairies under expansive skies, the captivating 'maple sugar country' surrounding the Great Lakes and St. Lawrence, and the rugged elegance of the Atlantic provinces' hills and picturesque shores.",

    " Renowned for its tranquility, Canada emerges as a peaceful, secure, and orderly nation. Notably, the rate of violent crime in Canada saw a decade-long decrease from 1993 to 2003. Diverging from its southern US neighbors, stringent firearm regulations are upheld, ensuring a safer environment. International students, akin to anywhere in the world, should exercise prudence, though Canada remains a relatively secure destination. For further insights into personal safety, students can engage with Canadian Education Centres or partake in safety orientation sessions at their respective institutions upon arrival."];

export const aboutCanada = [
    " Canada operates as a federal state, adhering to a parliamentary democracy and a constitutional monarchy, with Queen Elizabeth II as its reigning monarch. The nation boasts a bilingual identity, establishing English and French as official languages at the federal level.",

    "Distinguished as a profoundly advanced nation, Canada showcases an economy marked by diversity, fueled by its ample natural resources and robust trade partnerships. Its intricate ties with the United States, characterized by historical intricacies, contribute significantly to its economic framework.",

    "     For nearly three decades, beginning in 1994, Canada has consistently secured a place among the top ten most livable nations, a recognition bestowed by both the United Nations (UN) and the Economist Intelligence Unit."
];

export const requiredDocumentsforCanada = [
    "A valid passport.",
    "Official letter of acceptance from the designated Canadian university, college, or educational institution where the student is enrolled.",
    "Proof of sufficient funds to cover tuition fees, living expenses for the student, and any accompanying dependents, along with return transportation costs.",
    "In certain instances, a medical examination conducted by a doctor designated by the Canadian government may be necessary. The immigration officer will provide detailed instructions regarding this prerequisite."
];

export const universitiesAndCollegesinCanada = {
    ONTARIO: [
        "Algonquin College",
        "Beta College",
        "Cambrian College",
        "Canadore College",
        "Centennial College",
        "Conestoga College",
        "Confederation College",
        "Durham College",
        "Fanshawe College",
        "Fleming College",
        "George Brown College",
        "Georgian College",
        "Humber College",
        "Lambton College",
        "Loyalist College",
        "Mohawk College",
        "Niagara College",
        "Northern College",
        "Our Lady Seat of Wisdom College",
        "St. Clair College",
        "St. Lawrence College",
        "Sault College",
        "Seneca College",
        "Sheridan College",
        "Springfield College Brampton",
        "Algoma University",
        "University of Windsor",
        "University of Toronto",
        "York University",
        "University of Waterloo",
        "Queen’s University",
        "University of Guelph",
        "Trent University",
        "Lakehead University"
    ],
    BRITISHCOLUMBIA: [
        "Alexander College",
        "Camosun College",
        "College of New Caledonia",
        "Douglas College",
        "University Of British Columbia",
        "University of Northern British Columbia",
        "University of Fraser Valley",
        "Kwantlen Polytechnic University",
        "Capilano University",
        "Simon Fraser University",
        "University of Victoria",
        "Langara College",
        "Northern Lights College",
        "Okanagan College",
        "Royal Roads University",
        "Vancouver Community College"
    ],
    ALBERTA: [
        "Bow Valley College",
        "Lakeland College",
        "Lethbridge College",
        "Medicine Hat College",
        "NorQuest College",
        "Northern Alberta Institute of Technology",
        "Red Deer College",
        "Southern Alberta Institute of Technology",
        "MacEwan University",
        "University of Alberta",
        "University of Calgary",
        "University of Lethbridge"
    ],
    MANITOBA: [
        "Assiniboine Community College",
        "Red River College",
        "University Of Manitoba",
        "Manitoba Institute of Trades and Technology"
    ],
    NEWBRUNSWICK: [
        "Maritime College of Forest Technology",
        "McKenzie College",
        "New Brunswick College of Craft and Design",
        "New Brunswick Community College",
        "Oulton College"
    ]
};



export const newzealandcontent = {
    introduction: "New Zealand is situated in the South Pacific Ocean, approximately 2,162 kilometers (1,343 miles) away from Australia. The country comprises two primary landmasses: the imaginatively named North and South Islands. Covering a total land area of 270,000 square kilometers, it could fit into Australia 28 times and fit into the USA or Canada around 34 times. Notably, it's slightly larger than the United Kingdom.",
    people: "The population of New Zealand surpasses four million, and every individual within its borders either originates from or is descended from immigrants. Impressively, New Zealand is now home to people from over 145 nations. Recent additions from diverse places such as South Africa, Zimbabwe, and Somalia have further enriched its cultural fabric. New Zealand proudly embodies multiculturalism, embracing and rejoicing in the diversity of cultures and ethnicities that converge to create the nation's identity.",
    language: "The predominant language spoken and written in New Zealand is English. Possessing a solid command of the English language significantly enhances living and working experiences in the country, making communication smoother and more rewarding.",
    weather: "Being located in the Southern Hemisphere, New Zealand experiences seasons that are diametrically opposite to those in the northern hemisphere. Winter spans from June to August, while summer graces the land from December to February. Due to its proximity to the sea, the climate remains temperate and avoids extreme heat or cold in most inhabited areas. Though, certain regions, especially mountainous areas in winter, may exhibit more pronounced temperature ranges.",
    landscape: "Boasting 14 national parks, New Zealand dedicates a third of its territory to protected parks and reserves. Despite its relatively small size, the country offers an astonishing array of landscapes and vegetation. Majestic mountain ranges, expansive plains, towering forests, serene lakes, and hidden bays along the extended coastline contribute to the breathtaking beauty that New Zealand is renowned for. The nation lacks only deserts and icecaps among the various natural landscapes present across the globe.",
    whynewzealand: "Set in the southern expanse of the Pacific Ocean, New Zealand captivates with its breathtaking landscapes and vibrant cities. Comprising two principal islands, it spans a region akin to Japan, California, or Great Britain. The fusion of cultures, urban scenes, and captivating panoramas make it a truly diverse and fascinating destination. With a multicultural population exceeding four million, New Zealand hosts individuals from Pacific Islands, various Asian corners, India, Africa, the Middle East, and Europe. Notably, around 14 percent of the populace consists of indigenous Maori.",
    whynewzealandeducation: "New Zealand has emerged as a favored study destination for Indian students, indicating a burgeoning education relationship between the two nations. This popularity is driven by various factors:",
    educationSystem: "With over 90,000 international students estimated in 2008, New Zealand continues to attract learners seeking both quality education and diverse experiences. Its education system is internationally recognized for its excellence and innovation, offering flexible pathways for different study levels. New Zealand's pragmatic, modern, and desirable degrees are valued globally. In select domains like biotechnology, forensic science, and marine engineering, New Zealand's programs and degrees gain international acknowledgment. Graduates from New Zealand often hold influential positions, even including senior administrators and cabinet ministers in developing countries across Asia and the South Pacific.",
    FactsaboutNewZealand: [
        "Name: New Zealand, named after the Dutch province of Zeeland, or Aotearoa in Maori (meaning: land of the long white cloud)",
        "Population: 5 million (2021)",
        "Urban Population: 90%",
        "Capital: Wellington, with a population of 415,000",
        "Official Languages: English, Maori, and Sign Language",
        "Unemployment: 4%",
        "Currency: 1 New Zealand Dollar (NZ$) = 100 Cents",
        "Religion: 48.6% no religion, 37.3% Christians",
        "Timezones: 2",
        "National Symbols: Silver fern (plant), kiwi (bird), black, white, and red (colors)",
        "National Holidays: 6 February (Waitangi Day) and 25 April (Anzac Day)",
        "National Flag: The stars represent the constellation of the Southern Cross",
        "National Anthem: God Defend New Zealand",
        "History : New Zealand's history is intertwined with the Maori people, who settled as early as 1300 AD. European explorers like Abel Tasman and James Cook arrived in later years, eventually leading to the signing of the Treaty of Waitangi in 1840.",
        "Prime Minister: Jacinda Ardern"
    ],

}



export const qualityOfLifeContent = {
    "Quality Of Life": [
        "In many ways, it's not what New Zealand has that's important to quality of life – it's what they don't have!",
        "New Zealand doesn't have high crime rates, police don't carry guns, and instances of corruption are virtually unheard of.",
        "Abject poverty, hunger, pollution, congestion, health issues, and cramped city living are not prevalent in New Zealand.",
        "Acceptance of different views and ideas is a hallmark of New Zealand society.",
        "New Zealand is a modern, secular, democratic society with no ingrained class system.",
        "Freedom of speech, expression, and religious belief is guaranteed by law."
    ],
    "Housing": [
        "Currency converted to New Zealand dollars goes a long way in the real estate market.",
        "Homeownership is affordable for most, and suburban houses account for a significant portion of house sales.",
        "Inner-city and semi-rural living options are becoming increasingly popular."
    ],
    "Education": [
        "The NZ government-funded schooling system offers a comprehensive curriculum of academic, sporting, and skills-based learning options.",
        "Most schools, even in inner-city areas, have their own playing fields, gymnasiums, and swimming pools."
    ],
    "Cost Of Living": [
        "New Zealand generally offers a better balance between cost of living and lifestyle compared to many other places.",
        "Housing in New Zealand is often more reasonable than in parts of Asia, Europe, and North America.",
        "A wide range of competitively priced consumer goods is available, including motor vehicles, electronics, and petroleum products."
    ]
};


export const admissionRequirementsnewZealand = {
    "Admission Application Documents": [
        "To initiate the admission process, submit the completed application form (available at our offices) along with the following documents.",
        "The faculty at the respective university will make decisions, and the admission process typically takes around 4 to 6 weeks.",
    ],
    "Language Proficiency Requirements": [
        "For admission, proof of English language proficiency is established through the acceptance of IELTS exams.",
        "GRE and GMAT scores are not mandatory for admission purposes.",
        "Admission decisions are primarily based on the student's demonstrated academic capabilities."
    ],

    "Undergraduate and Diploma Programs": [
        "IELTS score of 5.5 (Academic)",
        "Completion of 10+2 level education"
    ],
    "Postgraduate Programs": [
        "IELTS score of 6.0 (Academic)",
        "Completion of a Bachelor's degree"
    ],
    "Master's Programs": [
        "IELTS score of 6.5 (Academic)",
        "Completion of a Bachelor's degree",
        "Possession of 2 – 3 years of work experience"
    ],
    "IELTS Exemptions Criteria": [
        "For Graduate Courses: Achieving over 65% marks in English in 10+2 from CBSE/ICSE Board.",
        "For Postgraduate Courses: Attaining above 75% marks in English in 10+2 from CBSE/ICSE Board.",
        "In New Zealand, many master's programs necessitate a minimum of 2 years of relevant work experience."
    ],
    "Intakes": "Most courses have two intakes per year, typically in February and July.",
    "Required Photocopies": [
        "Provide the following photocopies along with your application:",
        "Recent passport-size photographs (2)",
        "Copy of your passport",
        "Statement of purpose",
        "University application fee (if applicable)",
        "Certificate of completion for 10th Standard",
        "Certificate of completion for 12th Standard",
        "Provisional or official Bachelor's degree certificate",
        "Consolidated mark sheets for all semesters",
        "Provisional or official Master's degree certificate",
        "Additional qualifications like Diplomas or certificates",
        "Employment certificates (if applicable)",
        "Letter of reference from school or college",
        "Copy of passport's essential pages (first 3 & last 2 pages)",
        "Detailed CV or Resume (if applying for MBA)"
    ],
    "New Zealand Visa Procedure": [
        "For visa application, submit the filled application form (available at our offices) along with the required documents.",
        "Documents Required For Visa Application:",
        "Recent passport-size photographs (4)",
        "Copy of your passport",
        "Statement of purpose",
        "Visa application fee",
        "Certificate of completion for 10th Standard",
        "Certificate of completion for 12th Standard",
        "Provisional or official Bachelor's degree certificate",
        "Consolidated mark sheets for all semesters",
        "Provisional or official Master's degree certificate",
        "Additional qualifications like Diplomas or certificates",
        "Employment certificates (if applicable)",
        "Letter of reference from school or college",
        "Copy of passport's essential pages (first 3 & last 2 pages)",
        "Detailed CV or Resume (if applying for MBA)",
        "Financial documents – please get in touch with our office for more information"
    ]
};


export const newZealanduniversities = [
    "Auckland University of Technology",
    "Lincoln University",
    "Massey University",
    "The University of Auckland",
    "The University of Waikato",
    "University of Canterbury",
    "University of Otago",
    "Victoria University of Wellington",
    "Manukau Institute of Technology",
    "Unitec",
    "Whitireia Community Polytechnic",
    "Christchurch",
    "CPIT",
    "Dunedin",
    "Otago Polytechnic",
    "Hamilton",
    "Waikato Institute of Technology",
    "Napier",
    "Eastern Institute of Technology",
    "Nelson",
    "Nelson Marlborough Institute of Technology",
    "New Plymouth",
    "Western Institute of Technology",
    "Palmestorn North",
    "Universal College of Learning",
    "Rotorua",
    "Waiariki Institute of Technology",
    "Tauranga",
    "Bay of Plenty",
    "Wellington",
    "Auckland University of Technology",
    "Lincoln University",
    "Massey University",
    "The University of Auckland",
    "The University of Waikato",
    "University of Canterbury",
    "University of Otago",
    "Victoria University of Wellington",
    "Manukau Institute of Technology",
    "Unitec",
    "Whitireia Community Polytechnic",
    "Christchurch",
    "CPIT",
    "Dunedin",
    "Otago Polytechnic",
    "Hamilton",
    "Waikato Institute of Technology",
    "Napier",
    "Eastern Institute of Technology",
    "Nelson",
    "Nelson Marlborough Institute of Technology",
    "New Plymouth",
    "Western Institute of Technology",
    "Palmestorn North",
    "Universal College of Learning",
    "Rotorua",
    "Waiariki Institute of Technology",
    "Tauranga",
    "Bay of Plenty",
    "Wellington",
    "Whitireia Community Polytechnic",
    "Wellington Institute of Technology",
    "Whitireia Community Polytechnic",
    "Wellington Institute of Technology"
];



export const ukInformation = {
    introduction:
        "The United Kingdom of Great Britain and Northern Ireland, commonly known as the United Kingdom (UK) or Britain is a country in Europe, off the north-western coast of the continental mainland. It comprises England, Scotland, Wales and Northern Island. The United Kingdom includes the island of Great Britain, the north-eastern part of the island of Ireland, and many smaller islands within the Britain Isles. The capital and largest city is London, a global city and financial centre with a metropolitan area population of over 14 million. Other major cities include Birmingham, Manchester, Glasgow, Liverpool and Leeds."
    ,

    "Getting Around UK Towns And Cities": [

        "One of the best ways to see most UK cities is on foot. You will find that in most towns and cities all the local attractions are within a short walk of one another and walking around at your own pace will allow you to focus on the things that particularly appeal to you.",
        "When visiting somewhere for the first time make the local Tourist Information Centre you first stop. They are often located near to the railway station or in the centre of town. They will be able to provide you with a map of the local area help with accommodation, if you need it and suggest places of interest to visit.",
        "Many towns and cities offer tickets, such as one-day, three day and weekly travel cards that generally are better value than buying individual tickets and will allow you to use most public transport in the city."
    ],

    exploringOpportunities: [
        "Exploring UK as well as travelling independently around UK, you may find that your college or university also offers opportunities for you to see more of the country. Students' Unions often offer day trips to places of interest, which can be a fun day out and a good way to meet people. This may be especially true of international student societies during induction periods.",
        "You will also find that many student societies provide opportunities for travel. Joining a sports team that competes against teams from other institutions can be a good way to see other towns and cities, while outdoor activity societies such as walking or rock-climbing groups will give you a chance to see some beautiful countryside. Choirs may perform in churches and cathedrals around the country, while drama and dance groups may often undertake theatre tours during the holidays."
    ],

    "Why UK": [
        "Every country has its own teaching methods. To help prepare you, we've provided some information about the UK's teaching methods and details of the type of study support services available to students.",
        "There are also plenty of welfare support services available specifically for you as an international student."
    ],

    "Teaching Methods And Study Support": [
        "UK approaches to teaching have evolved over many hundreds of years and aim to encourage independent-thinking and confidence. Most students benefit from the continuing support of a tutor, from small classes or tutorial and seminar groups and from a range of teaching methods."
    ],

    "Student Welfare":
        "UK schools, colleges and universities have developed world-class student services. These, along with the welfare services provided in the wider community ensure really great support for international students."

};


export const studentlifeinUk = {
    "Life And Culture": [
        "In your native country, you're accustomed to various customs, behaviors, and social norms that might not even cross your mind due to familiarity. These nuances differ from culture to culture, contributing to the intrigue and thought-provoking nature of being in a foreign land.",
        "Distinct variations can be observed in attire, greetings, religious practices, eating habits, conduct in different scenarios, expression of opinions, and even attitudes towards animals or punctuality. Familiarize yourself with potential cultural differences by delving into information about the UK before your departure. Also, consider what aspects of your own culture you might need to communicate to others.",
    ],

    "Religion": [
        "If you practice a religion and maintain regular worship in your home country, you can continue to do so in the UK. The UK hosts all major world religions, and most cities have centers for Muslims, Hindus, Sikhs, Buddhists, synagogues, and churches of various denominations.",
        "Staying faithful to your beliefs can provide a connection to your homeland and facilitate swift friendships. The UK's multicultural society is reflected in the celebration of religious festivals, including those of Christianity, Islam, Hinduism, Judaism, Sikhism, and Buddhism.",
    ],

    "Food": [
        "The diversity of British society is reflected in the culinary offerings available in the UK. Supermarkets stock ingredients from regions such as Asia, Africa, the Caribbean, Latin America, and beyond.",
        "The British populace increasingly embraces healthy eating, with a wide selection of organic produce accessible in stores. Your culinary experience in the UK will encompass a rich assortment of meals and menus, ranging from traditional 'Sunday roasts' to international delights like Italian pizza and Singapore noodles.",
    ],

    "Making Friends": [
        "Being a student in the UK entails not only coursework and research, but also relaxation and enjoyment. Students enjoy discounts on numerous cultural activities and attractions, providing opportunities for social engagement with friends or solo excursions.",
        "UK educational institutions prioritize social interactions, offering diverse societies and groups catering to a broad range of interests. International students often share stories of forming friendships from around the globe. Building connections in the UK can greatly enhance your English language skills.",
    ],

    "Events And Celebrations": [
        "In addition to the prominent religious observances and holidays in the UK calendar, the year is peppered with traditional festivities. For instance, Bonfire Night on November 5th features organized firework displays and bonfires, commemorating the thwarting of the 1605 plot to blow up the Houses of Parliament by Guy Fawkes.",
        "Burns Night, held around January 25th, celebrates Scottish writer Robert Burns with communal 'Burns Suppers.' Wales boasts the Eisteddfod tradition, a celebration of music, literature, and performance. Multiple Eisteddfodau take place throughout Wales, including the National Eisteddfod, a vibrant youth arts festival that's among Europe's largest.",
    ],

    "Relaxation And Inspiration": [
        "Studying in the UK involves not only academic pursuits but also finding inspiration and delight outside coursework. Embrace your interests and uncover new pastimes, allowing you to be your authentic self. The UK's rich cultural scene is ever-present, regardless of your chosen study destination.",
        "The UK's music landscape is diverse and captivating, spanning genres like pop, rock, classical, folk, jazz, opera, and global music. From intimate clubs and local cafes to grand concert halls and music festivals, the live music experience thrives. University and college venues often host performances by independent artists and bands.",
    ],
};


export const visaApplicationProcedure = {
    applicationOrder: [
        "University acceptance letter, fees receipt, confirmation of accommodation (original and photocopies)",
        "Two additional inquiry forms",
        "Two passport-size photos",
        "Original and photocopy of bank loan sanction letter",
        "Photocopies of all qualifications",
        "Photocopy of any relevant work experience",
        "Letter of recommendation",
        "Letter from current employer",
        "Statement of Purpose",
    ],
    "Financial Documents:": ["Original bank statements",
        "Photocopy of income tax returns",
        "Original pay slips",
        "Photocopy of savings evidence",
        "Property documents",
        "Photocopies of any other financial documents"],
    "Sponsor's Documents:": ["Original sponsorship documentation", "Photocopies of financial documents",
        "Sponsor's contact details"]

};



export const ukUniversities = [
    "University west of scotland",
    "University west of london",
    "University of east london",
    "Coventry University",
    "Huddersfield university",
    "Birmingham city university",
    "Bath spa university",
    "Anglia ruskin university",
    "University of bedfordshire",
    "University of greenwich",
    "University of plymouth",
    "University of Oxford",
    "University of Cambridge",
    "University College London",
    "University of Edinburgh",
    "University of Manchester",
    "University of Dundee",
    "Glasgow University",
    "Durham University",
    "University of Law",
    "Swansea University",
    "Imperial College London",
    "London School of Economics",
    "University of Warwick",
    "University of St Andrews",
    "University of Bath",
    "King’s College London",
    "University of Exeter",
    "University of Bedfordshire",
    "University west of Scotlandx",
    "University of Greenwich",
    "Anglia Ruskin University",
    "Birmingham City University",
    "University of Bristol",
    "University of Sheffield",
    "University of Leeds",
    "University of Southampton",
    "University of Nottingham",
    "Queen Mary University of London",
    "Lancaster University",
    "Cranfield University",
    "De Montfort University",
    "Queen Margaret University",
    "University of Cumbria"
];


export const studyingInUSA = {
    introduction: [
        "Each year, countless individuals opt to pursue education in the USA. Renowned worldwide, the educational institutions in the USA offer an unparalleled standard of education. International students are drawn by the exceptional academic excellence, sought-after programs, and promising job prospects.",
        "Leading the World University rankings, American universities present an extensive array of courses. The majority of educational institutions across the United States foster strong connections with prominent employers. This facilitates students to engage in internships during their studies and secure employment shortly after graduation.",
        "For international students, the USA presents an array of attractions — a chance to access the world's most prestigious education, vibrant urban landscapes, serene parks, and a rich tapestry of artistic, cultural, political, and sporting events."
    ],

    "Advantages of Pursuing Education in the USA": [
        " Educational Excellence: The USA provides education of the highest caliber, with institutions that stand at the pinnacle of global rankings.",
        "Diverse Academic Offerings: American universities boast an extensive spectrum of programs to cater to diverse interests.",
        "Career Pathways: The strong partnerships between universities and employers facilitate internships and job prospects for students.",
        "Esteemed Credentials: Education from the USA carries immense prestige, elevating career opportunities across the globe.",
        "Cultural Enrichment: The USA's rich tapestry offers a range of cultural, artistic, and athletic events.",
        "Urban Vibrance: Experience life in bustling cities known for their innovation and dynamism.",
        "Natural Splendor: Delight in the picturesque landscapes of national parks and scenic vistas.",
        "Networking Opportunities: Forge international connections that hold the potential to shape your future career."
    ]
};

export const reasonsToChooseUSA = [
    "Quality Education: The USA is renowned for its exceptional education system. Its universities consistently rank among the top globally, offering rigorous and innovative academic programs.",

    "Diverse Programs: American institutions provide an extensive range of programs across various disciplines. From traditional subjects to cutting-edge fields, there's a diverse spectrum of choices.",

    "Global Recognition: A degree from a US university holds worldwide prestige. This recognition enhances career prospects and opens doors to opportunities across the globe.",

    "Research Opportunities: The USA is a hub for groundbreaking research. Students have access to advanced facilities, enabling them to contribute to pioneering discoveries.",

    "Internships and Employment: Universities often collaborate closely with industries, facilitating internships and work experiences that prepare students for real-world careers.",

    "Cultural Diversity: The USA's multicultural society offers exposure to various cultures, perspectives, and traditions. This enriching environment fosters tolerance and global understanding.",

    "Networking Potential: Studying in the USA exposes you to a diverse community of peers, professors, and professionals. This network can lead to lifelong connections and career opportunities.",

    "English Language Mastery: Immersion in an English-speaking environment sharpens language skills, preparing students for effective communication in an international setting.",

    "Innovative Pedagogy: American universities emphasize critical thinking, creativity, and problem-solving. Interactive teaching methods empower students to become independent learners.",

    "Varied Campus Life: From clubs and societies to cultural events and sports, US campuses offer a vibrant student life that promotes personal growth and holistic development.",

    "Flexibility in Education: The USA allows you to tailor your education with diverse electives and minors, giving you the freedom to explore your interests beyond your major.",

    "Career Support: Universities provide career services that assist students in finding internships, jobs, and career pathways that align with their academic pursuits.",

    "Cultural Enrichment: Museums, theaters, galleries, and cultural festivals offer numerous avenues to explore and appreciate art, history, and creativity.",

    "Adaptive Learning Environment: American classrooms encourage active participation, discussions, and diverse viewpoints, fostering a collaborative and open atmosphere.",

    "Leadership Opportunities: Many universities offer leadership development programs, empowering students to take on roles that enhance their skills and contribute to their communities.",

    "Entrepreneurial Spirit: The USA's innovation culture encourages entrepreneurship. Campuses often have resources to support students in launching their own ventures.",

    "Quality of Life: Alongside academic pursuits, the USA offers a high quality of life with modern amenities, healthcare, and safety, ensuring a comfortable stay.",
];


export const usavisaApplicationProcedure = {
    "Required Documents:": [
        "University acceptance letter, fees receipt, original and photocopies of accommodation confirmation.",
        "Two additional inquiry forms.",
        "Two passport-size photos.",
        "Original and photocopy of bank loan sanction letter.",
        "Photocopies of all qualifications.",
        "Photocopy of any relevant work experience.",
        "Letter of recommendation.",
        "Letter from current employer.",
        "Statement of Purpose."
    ],

    "Financial Documents:": [
        "Original bank statements.",
        "Photocopy of income tax returns.",
        "Original pay slips.",
        "Photocopy of savings evidence.",
        "Property documents.",
        "Photocopies of any other financial documents."
    ],

    "Sponsor's Documents:": [
        "Original sponsorship documentation.",
        "Photocopies of financial documents.",
        "Sponsor's contact details."
    ]
};




export const usauniversities = [
    "Harvard University",
    "Massachusetts Institute of Technology",
    "Stanford University",
    "California State University Los Angeles Campus",
    "Arizona State University",
    "Northeastern University",
    "The University of Texas at Dallas",
    "New York University",
    "The University of Illinois at Chicago",
    "University at Buffalo",
    "Texas A & M University",
    "University of California - Berkeley Campus",
    "Columbia University",
    "Georgia Institute of Technology",
    "New York Institute of Technology",
    "University of Southern California"
];





export const reasonsToChooseEurope = {
    introduction: [
        "When it comes to pursuing education abroad, Europe stands as a compelling choice. As experts in admissions, we understand the allure of exploring new horizons through studying in this diverse and vibrant continent."
    ],

    academicExcellence: [
        "Europe offers an unparalleled array of renowned universities and institutions. The quality of education is exceptional, providing a rich academic experience that spans various disciplines.",
        "With a multitude of programs, Europe caters to diverse interests and aspirations, ensuring that students find the perfect fit for their educational journey.",
        "From historic institutions to cutting-edge research centers, Europe's academic landscape is both prestigious and dynamic."
    ],

    culturalEnrichment: [
        "Studying in Europe is a chance to immerse yourself in a tapestry of cultures, languages, and traditions. The continent's rich history and modern dynamism coexist, offering students an experience that transcends the classroom.",
        "From exploring historic landmarks to participating in vibrant festivals, Europe's cultural diversity is a source of constant discovery and growth."
    ],

    internationalNetwork: [
        "Europe is a global hub for students from around the world. By studying here, you'll have the opportunity to build a diverse network of peers, mentors, and professionals from various backgrounds.",
        "This network will extend far beyond your academic journey, providing valuable connections and insights for your future career endeavors."
    ],

    cuttingEdgeResearch: [
        "Europe is at the forefront of innovation and research across diverse fields. Studying here means being exposed to groundbreaking advancements and contributing to the development of cutting-edge technologies and solutions.",
        "Engaging with pioneering research fosters an environment of curiosity, creativity, and critical thinking."
    ],

    languageOpportunities: [
        "Europe is a mosaic of languages, providing a unique opportunity for language learning and fluency. Studying in a multilingual environment opens doors to personal and professional growth.",
        "Whether you're refining your language skills or learning a new one, Europe's linguistic diversity enhances your global perspective."
    ]
};




export const studentLifeInEurope = [
    "Cultural Diversity: Europe is home to a diverse mix of cultures, languages, and traditions, providing a unique and enriching experience for international students.",
    "Vibrant Cities: European cities are known for their lively and cosmopolitan atmosphere, offering a blend of historical charm and modern amenities.",
    "Social Activities: Universities organize a range of social and cultural activities, including clubs, societies, events, and festivals, making it easy to meet new people and make friends.",
    "Campus Facilities: Most European universities have state-of-the-art facilities, including libraries, sports centers, and student lounges.",
    "Language Learning: Studying in Europe gives you the opportunity to learn or improve your language skills, as many programs are offered in English and other languages.",
    "Travel Opportunities: Europe's centralized location makes it convenient to travel and explore nearby countries and cities during breaks.",
    "Public Transportation: Efficient public transportation systems in European cities make it easy to navigate and explore your surroundings.",
    "Affordable Living: While certain cities may be more expensive, many European cities offer affordable living options for students.",
    "Part-Time Work: International students are often allowed to work part-time during their studies, helping to offset living costs and gain valuable work experience.",
    "Culinary Delights: European cuisine offers a diverse range of flavors, and students can enjoy a variety of local dishes and international options.",
    "Arts and Entertainment: European cities are known for their vibrant arts scenes, with theaters, galleries, museums, and music venues to explore.",
    "Festivals and Events: Throughout the year, various cultural festivals and events provide opportunities to experience local traditions and celebrations.",
    "Student Discounts: Many places offer student discounts, making entertainment, dining, and transportation more affordable.",
    "Health and Wellness: Universities often provide access to health services and wellness programs to support students' physical and mental well-being.",
    "International Communities: Europe's universities host a diverse community of international students, fostering a sense of belonging and cross-cultural friendships.",
    "Research Opportunities: European universities encourage research involvement, enabling students to engage in cutting-edge projects and contribute to their fields.",
    "Supportive Environment: Universities offer academic and personal support services to help students succeed in their studies and adapt to their new environment.",
    "Networking Opportunities: Student life in Europe allows you to connect with peers, professors, and professionals in your field, creating valuable networks.",
    "Outdoor Activities: Europe's natural landscapes offer opportunities for outdoor activities, from hiking and skiing to beach trips and cycling.",
    "Multicultural Experience: Interact with students from various countries, gaining insights into different perspectives and forming lifelong friendships.",
    "Workshops and Seminars: Participate in workshops, seminars, and guest lectures to enhance your knowledge and skills outside the classroom.",
    "Art and History: Immerse yourself in Europe's rich history and art heritage by visiting museums, galleries, and historical sites.",
    "Innovation and Research: Engage with innovative research and technologies across various fields, contributing to advancements and discoveries.",
    "Language Exchange: Connect with local students for language exchange, improving your language skills while helping them learn your language.",
    "Student Housing: Universities often provide options for student housing, offering a convenient and supportive living environment.",
    "Safety and Security: European cities are known for their safety, providing a comfortable environment for international students.",
    "Career Services: Universities offer career services to help students with internships, job placements, and skill development.",
    "Cultural Integration: Participate in local customs, celebrations, and traditions to fully integrate into the European culture.",
    "Independent Living: Student life in Europe encourages independence and self-reliance, helping you develop life skills.",
    "Lifelong Memories: Studying in Europe will create unforgettable memories, fostering personal growth and a global outlook."
];



export const admissionProcessEurope = [
    "Research Programs: Research universities and programs that align with your academic and career goals. Explore universities' websites and program details.",
    "Choose Programs: Select the programs you are interested in applying to and make note of their admission requirements.",
    "Application Preparation: Prepare your application documents, including academic records, language proficiency test scores, and recommendation letters.",
    "Language Proficiency: Depending on the program's language of instruction, you may need to provide English language proficiency test scores (e.g., IELTS, TOEFL).",
    "Entrance Exams: Some programs may require specific entrance exams (e.g., GRE, GMAT) depending on the field of study.",
    "Prepare Personal Statement: Write a compelling personal statement or statement of purpose explaining your academic background, goals, and reasons for choosing the program.",
    "Gather Transcripts: Obtain official transcripts of your academic records from your previous institutions.",
    "Letters of Recommendation: Request letters of recommendation from professors or professionals who can speak to your academic abilities and potential.",
    "Application Submission: Complete the online application form provided by the university. Submit all required documents along with the application fee.",
    "Application Deadlines: Be aware of application deadlines, as they vary depending on the university and program. Submit your application before the deadline.",
    "Wait for Response: After submitting your application, wait for the university's response regarding admission decisions.",
    "Conditional Offers: If accepted, you may receive a conditional offer based on fulfilling specific requirements (e.g., language proficiency).",
    "Accept Offer: Upon receiving an offer, carefully review the conditions and acceptance instructions. Accept the offer and pay any required deposit.",
    "Visa Application: If accepted, apply for a student visa to study in the respective European country.",
    "Financial Proof: Provide evidence of sufficient funds to cover tuition fees, living expenses, and other costs as required for the visa application.",
    "Health Insurance: Arrange for health insurance coverage as per the country's requirements.",
    "Plan Accommodation: Research and arrange for student accommodation options, either through the university or private rentals.",
    "Attend Orientation: Attend the university's orientation program to familiarize yourself with campus facilities, resources, and academic expectations.",
    "Registration: Complete the necessary registration and enrollment procedures as instructed by the university.",
    "Commence Studies: Begin your academic journey in Europe and make the most of your international education experience."
];

export const requiredDocumentsEurope = [
    "Completed Application Form",
    "Academic Transcripts and Certificates",
    "English Language Proficiency Test Scores (e.g., IELTS, TOEFL)",
    "Entrance Exam Scores (if applicable)",
    "Letters of Recommendation (usually 2-3)",
    "Personal Statement or Statement of Purpose",
    "Curriculum Vitae (CV) or Resume",
    "Passport Copy",
    "Passport-sized Photographs",
    "Proof of Financial Means (Bank Statements, Scholarship Documents)",
    "Visa Application Form",
    "Health Insurance Proof",
    "Previous Degree Certificates",
    "Portfolio (for creative programs)",
    "Application Fee Payment Proof",
    "Conditional Offer Acceptance Form (if applicable)",
    "Visa Interview Appointment Letter (if required)",
    "Other Relevant Documents as Requested by the University"
];

export const famousUniversitiesEurope = [
    "University of Oxford, United Kingdom",
    "University of Cambridge, United Kingdom",
    "Imperial College London, United Kingdom",
    "London School of Economics and Political Science, United Kingdom",
    "University College London, United Kingdom",
    "University of Edinburgh, United Kingdom",
    "University of Munich, Germany",
    "University of Paris-Saclay, France",
    "ETH Zurich - Swiss Federal Institute of Technology, Switzerland",
    "University of Amsterdam, Netherlands",
    "University of Copenhagen, Denmark",
    "University of Zurich, Switzerland",
    "École Polytechnique Fédérale de Lausanne (EPFL), Switzerland",
    "University of Helsinki, Finland",
    "KU Leuven, Belgium",
    "University of Barcelona, Spain",
    "Sorbonne University, France",
    "University of Vienna, Austria",
    "University of Milan, Italy",
    "Charles University, Czech Republic",
    "Stockholm University, Sweden",
    "University of Warsaw, Poland",
    "University of Oslo, Norway",
    "University of Porto, Portugal",
    "University of Geneva, Switzerland",
    "Trinity College Dublin, Ireland",
    "University of Manchester, United Kingdom",
    "Ludwig Maximilian University of Munich, Germany",
    "University of Bristol, United Kingdom"
];




